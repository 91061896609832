import { Col, Row } from "antd";
import React from "react";
import { Server } from "../../utils/Constant";
import "./Footer.css";

interface ICustomFooter {
  marginTop?: number;
  collections: string[];
  footerText: string;
  backgroundColor?: string;
  buttonText?: string;
  showButton?: boolean;
  onClick?(): void;
  onNext?(): void;
  onPrev?(): void;
}

export default function CustomFooter(props: ICustomFooter) {
  return (
    <div className="footerContainer">
      <Row justify="space-between" align="middle">
        <Col xs={2}>
          <span
            style={{ color: "white", fontSize: "1.3rem", fontWeight: "bold" }}
          >
            PARTNER BY:
          </span>
        </Col>
        {props.collections.map((item, index) => {
          return (
            <Col xs={2} key={index}>
              <img
                src={`${
                  !process.env.NODE_ENV ||
                  process.env.NODE_ENV === "development"
                    ? Server.dev
                    : Server.prod
                }${item}`}
                className="sponsorImage"
              ></img>
            </Col>
          );
        })}
        {props.showButton && (
          <Col xs={3}>
            <div
              style={{ marginBottom: 5 }}
              onClick={() => props.onNext && props.onNext()}
              className="sponsorButton"
            >
              {`NEXT >>`}
            </div>
            <div
              onClick={() => props.onPrev && props.onPrev()}
              className="sponsorButton"
            >
              {`<< PREVIOUS`}
            </div>
          </Col>
        )}
      </Row>
      <Row
        className="sponsorCredit"
        style={{ backgroundColor: props.backgroundColor }}
      >
        <Col xs={24}>
          <div className="sponsorCreditBot">
            <span
              style={{
                color: "white",
                fontSize: "1.5rem",
                fontWeight: "bold",
              }}
            >
              {props.footerText}
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
}
