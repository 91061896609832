import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import CustomFooter from "../../components/footer/Footer";
import Generator from "../../components/generator/Generator";
import CustomHeader from "../../components/header/Header";
import { IPeserta } from "../../model/Peserta";
import { ISettingBulanan } from "../../model/SettingBulanan";
import { ServiceHistoryPemenang } from "../../service/ServiceHistoryPemenang";
import { ServicePeserta } from "../../service/ServicePeserta";
import { ServiceSetting } from "../../service/ServiceSetting";
import { Server, SettingPageId } from "../../utils/Constant";
import { MaskingText, ToMoneyFormat } from "../../utils/Helper";
import "./HariRaya.css";

interface IHadiahGroupPlay {
  key: string;
  index: number;
}

export default function HariRaya() {
  const HttpHistoryPemenang = new ServiceHistoryPemenang();
  const HttpPeserta = new ServicePeserta();
  const HttpSetting = new ServiceSetting();
  const [dataPeserta, setDataPeserta] = useState<IPeserta[]>([]);
  const [dataPesertaWinner, setDataPesertaWinner] = useState<IPeserta[]>([]);
  const [setting, setSetting] = useState<ISettingBulanan | null>(null);
  const [groupPriceIndex, setGroupPriceIndex] = useState<IHadiahGroupPlay[]>(
    []
  );
  const [page, setPage] = useState(0);
  const [ctr, setCtr] = useState(0);

  useEffect(() => {
    getPeserta();
    getSetting();
  }, []);

  useEffect(() => {
    if (ctr > 50) {
      setCtr((prev) => prev - 50);
      onNextClick();
    }
  }, [ctr]);

  async function getSetting() {
    const results = await HttpSetting.GetSetting({
      id_undian: SettingPageId.hariRaya,
    });
    if (results.data && results.data.setting) {
      const temp = JSON.parse(results.data.setting);
      const tempHadiahGroup: IHadiahGroupPlay[] = [];
      temp.hadiahGroup.forEach((item, index) => {
        tempHadiahGroup.push({
          key: item,
          index,
        });
      });
      setSetting(temp);
      setGroupPriceIndex(tempHadiahGroup);
    }
  }

  async function getPeserta() {
    const results = await HttpPeserta.GetPeserta({
      id_undian: SettingPageId.hariRaya,
      all: true,
      latest: true,
    });
    const temp = dataPesertaWinner;
    for (let i = 0; i < 500; i++) {
      temp.push({ id: -1 } as IPeserta);
    }
    results.data
      .filter((itemFilter) => itemFilter.has_been_drawed)
      .forEach((item, index) => {
        temp[index] = item;
      });
    setDataPesertaWinner(temp);
    setDataPeserta(results.data);
  }

  function winnerSelected(e: IPeserta) {
    HttpHistoryPemenang.add({
      id_undian: SettingPageId.bulanan,
      id_peserta: e.id,
      hadiah: e.hadiah,
      hadiah_actual: e.hadiah,
    });
    // prize not found, dont render on tile
    const NOT_FOUND_INDEX = 999;
    const group = groupPriceIndex.find(
      (item) => item.key.toLowerCase() === e.hadiah.toLowerCase()
    );
    const groupIndex = group ? group.index : NOT_FOUND_INDEX;
    setDataPesertaWinner((prev) => {
      let skip = false;
      return prev.map((item, index) => {
        if (item.id === -1 && !skip && index > groupIndex * 10 - 1) {
          item = e;
          skip = true;
        }
        return item;
      });
    });
    setCtr((prev) => prev + 1);
  }

  function onNextClick() {
    const max =
      dataPeserta.filter((item) => item.status.toLowerCase() === "menang")
        .length / 50;
    if (page + 1 < max) {
      const pagginationNum =
        dataPeserta.filter((item) => item.has_been_drawed).length / 50;
      if (page + 1 < Math.ceil(pagginationNum)) {
        setPage((prev) => prev + 1);
      }
    }
  }

  function onPrevClick() {
    if (page > 0) {
      setPage((prev) => prev - 1);
    }
  }

  function renderData(group: number) {
    const startIndex = group * 10 + 50 * page;
    const temp: any = [];
    for (let i = startIndex; i < startIndex + 10; i++) {
      temp.push(
        <div
          key={i + startIndex}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="hariRayaWinnerSlot" style={{ height: 30 }}>
            <div className="hariWinnerSlotName">
              {dataPesertaWinner.length > i - 10
                ? MaskingText(
                    dataPesertaWinner[i - 10].nama,
                    setting?.hiddenUser
                  )
                : ""}
            </div>
            <div className="winnerSlotNumber">{i - 9}</div>
            <div className="winnerSlotStart">
              <img
                style={{ width: "100%", height: "100%" }}
                src="./assets/bulanan/star.png"
              ></img>
            </div>
          </div>
        </div>
      );
    }
    return temp;
  }

  if (!setting) {
    return null;
  }

  return (
    <div
      className="containerCover"
      style={{
        backgroundImage: `url(${
          !process.env.NODE_ENV || process.env.NODE_ENV === "development"
            ? Server.dev
            : Server.prod
        }${setting.background})`,
      }}
    >
      <div className="containerWraper">
        <CustomHeader
          logo={setting.logo}
          backgroundColor={setting.warnaHeader}
          label={setting.textBerjalan}
        ></CustomHeader>
        <Row style={{ paddingTop: 20 }}>
          <Col xs={12}>
            <img
              className="logoHeader"
              src={`${
                !process.env.NODE_ENV || process.env.NODE_ENV === "development"
                  ? Server.dev
                  : Server.prod
              }${setting.logoUndian}`}
            ></img>
          </Col>
          <Col xs={12}>
            <Generator
              showHidden={setting.hiddenUser}
              title={setting.textHeader}
              auto={setting.isAuto}
              onWinnerSelected={(e) => winnerSelected(e)}
              data={dataPeserta}
              dataPer={50}
              timing={setting.waktuDelay}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }} justify="space-around">
          {groupPriceIndex.map((item, index) => {
            return (
              <Col xs={4} key={index}>
                <div className="headerWinner">
                  IDR {ToMoneyFormat(item.key)}
                </div>
                {renderData(index + 1)}
              </Col>
            );
          })}
          <Col xs={24}>
            <CustomFooter
              onNext={() => onNextClick()}
              onPrev={() => onPrevClick()}
              showButton={true}
              backgroundColor={setting.warnaFooter}
              footerText={setting.textFooter}
              collections={setting.sponsor}
              marginTop={20}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
