import { useEffect, useRef, useState } from "react";
import { IUploadImage } from "./model/IUploadImage";
import { ServiceUploadImage } from "./service/ServiceUploadImage";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Server } from "../../utils/Constant";

interface IUploadImageLocal {
  multiple?: boolean;
  onUpload?(imageUrl: string[]): void;
  onUpdate?(imageUrl: string, indexToUpdate: number): void;
  onDelete?(indexToDelete: number): void;
  onUploadMultiple?(response: IUploadImage[]): void;
  collections?: string[];
}

interface IImageList {
  url: string | null;
}

export default function UploadImage(props: IUploadImageLocal) {
  const idInput = Math.floor(Math.random() * 9999999);
  const UploadImageHttp = new ServiceUploadImage();
  const refInput = useRef<any>();
  const [imageUrl, setImageUrl] = useState<any | null>(null);
  const [imageListFile, setImageListFile] = useState<IUploadImage[]>([]);
  const [imageList, setImageList] = useState<IImageList[]>([]);
  const [selectedIndexToUpdate, setSelectedIndexToUpdate] = useState(-1);

  useEffect(() => {
    if (props.onUploadMultiple) {
      props.onUploadMultiple(imageListFile);
    }
  }, [imageListFile]);

  useEffect(() => {
    if (imageUrl) {
      if (props.multiple) {
        setImageList((prev) => [
          ...prev,
          {
            url: imageUrl,
          },
        ]);
      } else {
        const temp = [...imageList];
        temp.push({ url: imageUrl });
        setImageList(temp);
      }
    }
  }, [imageUrl]);

  async function onChange(e: any) {
    let temp: string[] = [];
    for await (const item of e.target.files) {
      const reader = new FileReader();
      reader.readAsDataURL(item);
      const result = await UploadImageHttp.AddImage(item);
      temp.push(result.data.publicUrl);
    }
    if (selectedIndexToUpdate !== -1 && props.onUpdate) {
      props.onUpdate(temp[0], selectedIndexToUpdate);
      setSelectedIndexToUpdate(-1);
    } else if (props.onUpload) {
      props.onUpload(temp);
    }
  }

  async function onUpdate(index: number) {
    refInput.current.click();
    setSelectedIndexToUpdate(index);
  }

  async function deleteImage(index: number) {
    if (props.onDelete) {
      props.onDelete(index);
    }
  }

  function setMultiple() {
    if (props.multiple) {
      return true;
    }
    return false;
  }

  function parseFileName(file: string) {
    const temp = file.split("/");
    const fileName = temp[temp.length - 1];
    return `${fileName}`;
  }

  return (
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div style={{ flexBasis: "7%" }}>
          <label
            htmlFor={`idUpload${idInput}`}
            style={{
              marginLeft: 5,
              backgroundColor: "#038aff",
              color: "white",
              padding: 7,
              borderRadius: 5,
              cursor: "pointer",
              display: props.multiple ? "table" : "none",
            }}
          >
            <span style={{ marginLeft: 5 }}>Upload</span>
          </label>
          <input
            ref={refInput}
            multiple={setMultiple()}
            style={{ display: "none" }}
            id={`idUpload${idInput}`}
            type="file"
            onChange={(e) => onChange(e)}
          ></input>
        </div>
      </div>
      <div style={{ marginTop: 20, display: "flex", flexWrap: "wrap" }}>
        {props.collections &&
          props.collections.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  padding: 5,
                  border: "2px solid #dedede",
                  borderRadius: 5,
                  width: 300,
                  marginRight: 5,
                  marginBottom: 5,
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                {props.multiple && (
                  <div
                    onClick={() => deleteImage(index)}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginBlock: 5,
                      right: "2%",
                      cursor: "pointer",
                      position: "absolute",
                      zIndex: 9999,
                    }}
                  >
                    <CloseCircleOutlined
                      style={{
                        color: "white",
                        fontSize: 25,
                      }}
                    ></CloseCircleOutlined>
                  </div>
                )}
                <div
                  onClick={() => onUpdate(index)}
                  style={{
                    backgroundImage: `url(${
                      !process.env.NODE_ENV ||
                      process.env.NODE_ENV === "development"
                        ? Server.dev
                        : Server.prod
                    }/${item})`,
                    width: "100%",
                    height: 150,
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: "#1C282C",
                  }}
                ></div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
