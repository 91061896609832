import { Card, Button, Form, Input, notification, Spin } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { ServiceLogin } from "../../service/ServiceLogin";
import { TokenAtomState } from "../../state/AtomToken";
import { KeyToken } from "../../utils/Constant";
import "./Login.css";

export default function Login() {
  const navigate = useNavigate();
  const HttpLogin = new ServiceLogin();
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [, setTokenAtom] = useRecoilState(TokenAtomState);
  const [isFetch, setIsFetch] = useState(false);

  console.log("Ver 1.1");

  async function onFinish() {
    if (username && password) {
      const results = await HttpLogin.login(username, password);
      if (results.data.status !== 400) {
        setTokenAtom(results);
        localStorage.setItem(KeyToken, JSON.stringify(results.data));
        navigate("/");
      } else {
        notification.open({
          message: "Mohon Periksa Username dan Password Anda",
          placement: "bottomRight",
          duration: 2000,
          type: "error",
        });
      }
    }
  }

  async function forget() {
    if (username) {
      setIsFetch(true);
      if (confirm("Apakah Anda Yakin Ingin Melakukan Reset Password ?")) {
        const results = await HttpLogin.forgetPassword(username);
        setIsFetch(false);
        if (results.status === 200) {
          notification.open({
            message: "Silahkan Check Email Anda",
            placement: "bottomRight",
            duration: 2000,
            type: "success",
          });
        }
      }
    } else {
      notification.open({
        message: "Mohon Input Username Untuk Melakukan Reset Password",
        placement: "bottomRight",
        duration: 2000,
        type: "error",
      });
    }
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "100%",
        alignItems: "center",
      }}
    >
      <div style={{ width: 500 }}>
        <Card>
          <Form
            labelCol={{ span: 5 }}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input onChange={(e) => setUsername(e.target.value)} />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password onChange={(e) => setPassword(e.target.value)} />
            </Form.Item>
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                flexDirection: "column",
              }}
            >
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Login
                </Button>
              </Form.Item>
              <Form.Item>
                {isFetch ? (
                  <Spin></Spin>
                ) : (
                  <Button
                    onClick={() => forget()}
                    type="link"
                    htmlType="button"
                  >
                    Lupa Password
                  </Button>
                )}
              </Form.Item>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
}
